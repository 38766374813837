import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ children, content, ...restProps }) => (
  <p className="title" {...restProps}>
    {' '}
    {children || content}{' '}
  </p>
);

Title.defaultProps = {
  children: null,
  content: null,
};

Title.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
};

export default Title;
