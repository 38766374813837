import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { setPage } from '../store/app';
import Section from '../components/shared/section';
import Loader from '../components/shared/loader';
import Title from '../components/shared/title';
import t from '../locales';

const RegisterSuccess = ({ setPage, ...props }) => {
  let isLoading = true;
  if (!props.location?.state?.successSend) {
    if (typeof window !== 'undefined') navigate(`/`, { replace: true });
  } else {
    isLoading = false;
  }
  useEffect(() => {
    setPage('register-success');
  }, [setPage]);

  return (
    <Section className="register-success bg-white">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <i className="checkbox-check-icon hm-check teal" />
          </div>
          <Title className="title" content={t('registerSuccess.thanksForMessage')} />
          <div>{t('registerSuccess.formWasSend')}</div>
        </>
      )}
    </Section>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
  })
)(RegisterSuccess);
